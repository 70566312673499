<template>
  <div ref="root" class="relative pb-[66.666%] rounded-[14px] overflow-hidden">
    <div class="wistia_responsive_wrapper">
      <div
        class="wistia_embed"
        :class="`wistia_async_${mediaId} preload=true playerColor=6366f1`"
      ></div>
    </div>
    <Transition name="fade">
      <div class="absolute inset-0" v-show="!played">
        <img
          class="absolute inset-0 w-full h-full object-cover"
          :src="poster"
          alt=""
        />
        <button
          class="absolute inset-0 flex justify-center items-center w-full cursor-pointer hover:opacity-80 transition-opacity duration-300"
          @click="wistiaVideo.play()"
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="relative z-20 rounded-full md:translate-y-3"
          >
            <circle
              cx="50"
              cy="50"
              r="48"
              fill="white"
              fill-opacity="0.32"
              stroke="white"
              stroke-width="4"
            ></circle>
            <path
              d="M60 50.2679C61.3333 51.0377 61.3333 52.9623 60 53.7321L46.5 61.5263C45.1667 62.2961 43.5 61.3338 43.5 59.7942L43.5 44.2058C43.5 42.6662 45.1667 41.7039 46.5 42.4737L60 50.2679Z"
              fill="white"
            ></path>
          </svg>
        </button>
      </div>
    </Transition>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { onClickOutside } from "@vueuse/core";

const props = defineProps<{
  mediaId: string;
  poster: string;
}>();

const root = ref(null as unknown as HTMLElement);
const played = ref(false);
const wistiaVideo = ref<any>(null);

onClickOutside(root, () => {
  if (wistiaVideo != null) {
    wistiaVideo.value.pause();
  }
});

onMounted(() => {
  addScript(`https://fast.wistia.com/embed/medias/${props.mediaId}.jsonp`);
  addScript("https://fast.wistia.com/assets/external/E-v1.js");
});

initId(props.mediaId, () => {
  window._wq.push({
    id: props.mediaId,
    options: {
      autoPlay: false,
      controlsVisibleOnLoad: false,
      endVideoBehavior: "loop",
      foam: true,
      fullscreenButton: false,
      playbar: false,
      playButton: false,
      playPauseNotifier: false,
      resumable: false,
      settingsControl: false,
      videoFoam: true,
      volumeControl: false,
      smallPlayButton: false,
    },
    onReady: function (video: any) {
      wistiaVideo.value = video;
      video.bind("play", () => {
        played.value = true;
      });
      video.bind("pause", () => {
        played.value = false;
      });
    },
  });
});
</script>
<script lang="ts">
const loaded = new Set<string>();
const ids = new Set<string>();

function addScript(src: string) {
  if (loaded.has(src)) {
    return;
  }

  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  loaded.add(src);
  document.body.append(script);
}

declare global {
  interface Window {
    _wq: any;
  }
}

window._wq = window._wq || [];
function initId(id: string, fn: () => void) {
  if (ids.has(id)) {
    return;
  }

  ids.add(id);
  fn();
}
</script>
<style lang="postcss" scoped>
.poster {
  filter: blur(5px);
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.wistia_responsive_wrapper {
  @apply absolute inset-0;
}

.wistia_responsive_wrapper::v-deep(.w-video-wrapper) {
  @apply !bg-white;
}
</style>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 300ms ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
